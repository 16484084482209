
import { computed, defineComponent, ref } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Regionen',
  components: {
    ModalButton,
    Modal,
  },
  setup() {
    const active = ref('');
    const show = computed(() => (name: string) => name == active.value);

    const activate = (name: string) => {
      active.value = name;
    };

    const deactivate = () => {
      active.value = '';
    };

    return {
      show,
      activate,
      deactivate,
    };
  },
});
